import classes from './CircleProgress.module.css'

const CircleProgress = ({value,progressColor='#21a6a6',background='#fff', titleColor= '#afafaf',ShowStroke=false}) => {

    let color = '#21a6a6'
    if( progressColor ){
      color =  progressColor
    }else{
      color = value > 49.5 ? 'green' : '#21a6a6'
    }

    if( ShowStroke ){
      color = value > 49.5 ? 'green' : 'red'
    }
    

  return (

    <div className={classes.Card}>
        <div className={classes.Percent}>
        <svg style={{ background: background , borderRadius: '50%' }}>
            <circle cx="35" cy="35" r="30" style={{stroke:ShowStroke ? color : '#fff' }}></circle>
            <circle cx="35" cy="35" r="30" style={{'--percent': value ,  stroke:color}}></circle>
        </svg>
        <div className={classes.Number}>
            <h3 style={{ color: titleColor  }} >{value}<span>%</span></h3>
        </div>
        </div>
    </div>
   
  );
}

export default CircleProgress;
