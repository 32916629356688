import {Col, Container, Row, Tab, Tabs} from 'react-bootstrap';
import classes from './style/TrackingDetailsAfterEnroll.module.css';
import {useParams, useLocation} from 'react-router-dom';
import {useEffect, useState} from 'react';
import {FaCircle, FaRegCheckSquare} from 'react-icons/fa';
import practicalTaskClasses from '../Courses/style/PracticalTaskUplaod.module.css';
import {NavLink} from 'react-router-dom';
import {Modal} from 'react-bootstrap';
import MainButton from '../../UI/main-button/MainButton';
import CancelButton from '../../UI/cancel-button/CancelButton';
import {
  addTrackNote,
  editTrackNote,
  sendInitiativeProf,
  updateProgress,
  updateProgressAutomatic,
} from '../Tracking/Trackings.service';

import {getActivityData} from './Trackings.service';

import moment from 'moment';
import axios from 'axios';
import {useNavigate} from 'react-router-dom';
import YouTube from 'react-youtube';
import TrackingDetailsCard from './TrackingDetailsCard';
import { getDomain } from '../../helpers/getDomain';

const UserTrackingActvitiyAddProgress = () => {
  const videoOptions = {
    playerVars: {
      // You can add additional YouTube player options here
      autoplay: 0,
    },
  };

  const navigate = useNavigate();

  const [show, setShow] = useState<boolean>(false);

  const [showGame, setShowGame] = useState<boolean>(false);

  const [confirmUpdate, setConfirmUpdate] = useState<boolean>(false);

  const [showAddNote, setShowAddNote] = useState<boolean>(false);

  const [confirmDeleteNote, setConfirmDeleteNote] = useState<boolean>(false);

  const [showAllNotes, setShowAllNotes] = useState<boolean>(false);

  const [selectedFile, setSelectedFile] = useState<File | null>(null);

  const {actId, trackId, date, userId} = useParams();

  const [activityData, setActivityData] = useState<any>();

  const [selectedNoteId, setSelectedNoteId] = useState<any>();

  const [selectedNoteValue, setSelectedNoteIdValue] = useState<any>();

  const [profMidea, setprofMidea] = useState<any>();
  const location = useLocation();
  const item = location.state.item;

  const [userActivityId, setUserActivityId] = useState(null);

  const [showUpdateProgress, setShowUpdateProgress] = useState<boolean>(false);

  const [progress, setProgress] = useState<number>(0);

  const [styleProgress, setStyleProgress] = useState<number>(0);

  const [autoUpdate, setAutoUpdate] = useState<number>(1);

  const getData = async () => {
    let result = await getActivityData(actId!, date!, userId!, trackId!);
    if (result.response) {
      console.log('progress data', result.response);
      setActivityData(result.response.data);
      setprofMidea(
        result.response.data?.media?.profs.length
          ? result.response.data?.media?.profs[0].media_path
          : null,
      );
      setAutoUpdate(result.response.data.auto_progress_update);
    }
  };

  const deleteNote = async (noteId: any) => {
    let token = localStorage.getItem('token');
    try {
      let result = await axios.delete(
        `${getDomain()}/api/trackingsystem/note/${noteId}`,

        {
          headers: {
            'Content-Type': 'multipart/form-data',
            Authorization: `Bearer ${token}`,
          },
        },
      );

      if (result.data.status) {
        setConfirmDeleteNote(false);
        getData();
      }
    } catch (e) {}
  };

  const addNote = async (actId: any) => {
    let data = new FormData();
    data.append(`note`, selectedNoteValue);
    data.append(`user_tracking_activity_id`, actId);

    console.log(data);
    let result = await addTrackNote(data);

    if (result.response.status) {
      setShowAddNote(false);
      getData();
    }
  };

  const editNote = async () => {
    let data = new FormData();
    data.append('note', selectedNoteValue);
    data.append('id', selectedNoteId);
    let result = await editTrackNote(data);

    if (result.response.status) {
      setShow(false);
      getData();
    }
  };

  const updateAuto = async (id: any, progress: any) => {
    let data = new FormData();
    data.append('user_activity_id', activityData.user_activity_id);
    data.append('progress', progress);
    data.append('media_id', id);
    let result = await updateProgressAutomatic(data);

    if (result.response.status) {
      getData();
    }
  };

  const handleFileChange = async (event: any) => {
    const file = event.target.files[0];
    setSelectedFile(file);

    let data = new FormData();
    data.append('user_activity_id', activityData.user_activity_id);
    data.append('activity_id', String(actId));

    data.append('prof', activityData?.media?.profs[0]?.id ?? 0);
    const fileExtension = file.name.split('.').pop();

    if (
      [
        'png',
        'PNG',
        'jpg',
        'JPG',
        'jpeg',
        'JPEG',
        'webp',
        'WEBP',
        'svg',
        'SVG',
      ].includes(fileExtension)
    ) {
      data.append('type', 'document');
    } else {
      data.append('type', 'video');
    }

    data.append('file', activityData?.media?.profs[0]?.id ? 0 : file);
    let result = await sendInitiativeProf(data);

    if (result.response.status) {
      getData();
    }
  };

  const handleClick = (e: any) => {
    const bar = e.currentTarget;

    const clickPosition = e.clientX - bar.getBoundingClientRect().left;

    const totalWidth = bar.clientWidth;
    let newProgress = (clickPosition / totalWidth) * 100;
    if (newProgress >= 95) {
      newProgress = 100;
    }
    setProgress(Math.floor(newProgress));
    if (newProgress >= 90) {
      setStyleProgress(85);
    } else {
      setStyleProgress(Math.floor(newProgress));
    }
  };

  const updateManualProgress = async () => {
    let data = new FormData();
    data.append('user_activity_id', String(userActivityId));
    data.append('progress', String(Math.floor(progress)));
    let result = await updateProgress(data);

    if (result.response.status) {
      setShowUpdateProgress(false);
    }
  };

  useEffect(() => {
    getData();
  }, []);

  return (
    <div className="mt-md-5">
      <Container>
        <Row className="d-flex justify-content-between">
          <Col xs={12} lg={4} className="mt-3 mb-3">
            <TrackingDetailsCard tracking={item!} />
          </Col>

          <Col xs={12} lg={8} className="mt-3 mb-3">
            <div className="mb-5">
              <h4>About Activity</h4>
              <p className="text-font-muted">{activityData?.description}</p>
            </div>

            {activityData?.intro_video ? (
              <div className="mb-5">
                <h4>Intro Video</h4>
                <div className="media mt-3">
                  <div className="d-flex justify-content-between flex-column mb-3">
                    <div>
                      <img
                        src="/icons/video.png"
                        alt="image"
                        style={{width: '50px', margin: '0 20px 0 0'}}
                      />
                      <span>Media en 1</span>
                    </div>

                    <video
                      width="320"
                      height="240"
                      controls
                      className="mt-4"
                      style={{borderRadius: '15px'}}>
                      <source
                        src={activityData?.intro_video}
                        type="video/mp4"
                      />
                    </video>
                  </div>
                </div>
              </div>
            ) : (
              ''
            )}

            {activityData?.auto_progress_update ? (
              <div>
                {activityData?.media?.attachments?.length > 0 && (
                  <div className="mb-5">
                    <h4>Atachments</h4>
                    <div className="attachments mt-3">
                      {activityData?.media?.attachments.map((a: any) => {
                        return (
                          <div className="d-flex justify-content-between align-items-center mb-3">
                            <div>
                              <img
                                src="/icons/file.png"
                                alt="image"
                                style={{width: '50px', margin: '0 20px 0 0'}}
                              />
                              <span>{a.name_en}</span>
                            </div>

                            <div>
                              <div>
                                {a.is_answered ? (
                                  <FaRegCheckSquare
                                    size={19}
                                    color="green"
                                    style={{
                                      verticalAlign: 'text-bottom',
                                      margin: '1.5rem',
                                    }}
                                  />
                                ) : (
                                  <a
                                    href={a.media_path}
                                    target="_blank"
                                    onClick={() => {
                                      updateAuto(a.id, 100);
                                    }}>
                                    <img
                                      src="/icons/download.png"
                                      alt="image"
                                      style={{
                                        width: '50px',
                                        margin: '0 20px 0 0',
                                      }}
                                    />
                                  </a>
                                )}
                              </div>
                            </div>
                          </div>
                        );
                      })}
                    </div>
                  </div>
                )}

                {activityData?.media?.videos.length != 0 && (
                  <div className="mb-5">
                    <h4>Media</h4>
                    <div className="media mt-3">
                      <div
                        className={`d-flex justify-content-between ${
                          activityData?.media?.videos[0].is_answered == 0
                            ? 'flex-column'
                            : ''
                        } mb-3`}>
                        <div>
                          <img
                            src="/icons/video.png"
                            alt="image"
                            style={{width: '50px', margin: '0 20px 0 0'}}
                          />
                          <span>{activityData?.media?.videos[0].name_en}</span>
                        </div>

                        {activityData?.media?.videos[0].is_answered ? (
                          <FaRegCheckSquare
                            size={19}
                            color="green"
                            style={{
                              verticalAlign: 'text-bottom',
                              margin: '1.5rem',
                            }}
                          />
                        ) : (
                          <video
                            width="320"
                            height="240"
                            controls
                            className="mt-4"
                            style={{borderRadius: '15px'}}
                            onEnded={() => {
                              updateAuto(
                                activityData?.media?.videos[0].id,
                                100,
                              );
                            }}>
                            <source
                              src={activityData?.media?.videos[0].media_path}
                              type="video/mp4"
                            />
                          </video>
                        )}
                      </div>
                    </div>
                  </div>
                )}
                {activityData?.media?.quizs?.data && (
                  <div className="mb-5">
                    <h4>Quiz</h4>
                    <div className="quiz mt-3">
                      <div
                        className="d-flex justify-content-between align-items-center mb-3"
                        onClick={() => {
                          navigate(`/tracking-system-quiz`, {
                            state: {
                              questions: activityData?.media?.quizs.data,
                              mediaId: activityData?.media?.quizs.id,
                              userActivityId: activityData?.user_activity_id,
                            },
                          });
                        }}>
                        <div className="d-flex justify-content-between align-items-center">
                          <img
                            src="/icons/quize.png"
                            alt="image"
                            style={{width: '50px', margin: '0 20px 0 0'}}
                          />
                          <div>
                            <span>Quiz Title</span>
                            <p className="m-0" style={{color: '#b3b3b3'}}>
                              Quiz,{' '}
                              {activityData?.media?.quizs.data.questions.length}{' '}
                              Questions
                            </p>
                          </div>
                        </div>
                        {activityData?.media?.game[0].is_answered ? (
                          <FaRegCheckSquare
                            size={19}
                            color="green"
                            style={{
                              verticalAlign: 'text-bottom',
                              margin: '1.5rem',
                            }}
                          />
                        ) : (
                          ''
                        )}
                      </div>
                    </div>
                  </div>
                )}
                {activityData?.media?.game.length != 0 && (
                  <div className="mb-5">
                    <h4>Game</h4>
                    <div
                      className="media mt-3"
                      onClick={() => setShowGame(true)}>
                      <div
                        className={`d-flex justify-content-between ${
                          activityData?.media?.game[0].is_answered == 0
                            ? 'flex-column'
                            : ''
                        } mb-3`}>
                        <div>
                          <img
                            src="/icons/video.png"
                            alt="image"
                            style={{width: '50px', margin: '0 20px 0 0'}}
                          />
                          <span>Game</span>
                        </div>

                        {activityData?.media?.game[0].is_answered ? (
                          <FaRegCheckSquare
                            size={19}
                            color="green"
                            style={{
                              verticalAlign: 'text-bottom',
                              margin: '1.5rem',
                            }}
                          />
                        ) : (
                          ''
                        )}
                      </div>
                    </div>
                  </div>
                )}

                {activityData?.media?.social_media.length != 0 && (
                  <div className="mb-5">
                    <h4>YouTube</h4>
                    <div className="media mt-3">
                      <div
                        className={`d-flex justify-content-between ${
                          activityData?.media?.social_media[0].is_answered == 0
                            ? 'flex-column'
                            : ''
                        } mb-3`}>
                        <div>
                          <img
                            src="/icons/video.png"
                            alt="image"
                            style={{width: '50px', margin: '0 20px 0 0'}}
                          />
                          <span>YouTube</span>
                        </div>

                        {activityData?.media?.videos[0].is_answered ? (
                          <FaRegCheckSquare
                            size={19}
                            color="green"
                            style={{
                              verticalAlign: 'text-bottom',
                              margin: '1.5rem',
                            }}
                          />
                        ) : (
                          <YouTube
                            videoId={
                              activityData?.media?.social_media[0].media_path
                            }
                            opts={videoOptions}
                            onEnd={() => {
                              updateAuto(
                                activityData?.media?.social_media[0].id,
                                100,
                              );
                            }}
                          />
                        )}
                      </div>
                    </div>
                  </div>
                )}
                <div className="mb-5">
                  <h4>Add Proof</h4>
                  <div className="proof text-center">
                    {!selectedFile && (
                      <div
                        className={`${practicalTaskClasses.UploadBtnWrapper} w-100 mb-4 mt-3`}>
                        <button className="w-100 p-4">
                          <img
                            alt="img"
                            src="/practical-task/clip.png"
                            style={{width: '55px'}}
                          />
                          <div
                            style={{
                              color: '#21a6a6',
                              marginTop: '1rem',
                              fontWeight: 'normal',
                            }}>
                            <strong style={{textDecoration: 'underline'}}>
                              Click
                            </strong>{' '}
                            to attach your file
                          </div>
                        </button>
                        <input
                          type="file"
                          name="myfile"
                          className={practicalTaskClasses.UploadBtnWrapper}
                          onChange={handleFileChange}
                          id="pictureX"
                        />
                      </div>
                    )}
                    {activityData?.media?.profs.length &&
                    activityData?.media?.profs[0].status == 0 &&
                    activityData?.media?.profs[0].prof_remarkable == 1 ? (
                      <span
                        style={{
                          color: '#fff',
                          background: '#21a6a6',
                          padding: '10px 25px',
                          borderRadius: '10px',
                          fontWeight: '500',
                        }}>
                        waiting for marking
                      </span>
                    ) : (
                      ''
                    )}
                    {selectedFile && (
                      <div
                        className={`${practicalTaskClasses.FilePreview} d-flex justify-content-between align-items-center`}>
                        <div>
                          <img
                            alt="img"
                            src="/practical-task/clip.png"
                            style={{width: '26px'}}
                          />
                        </div>

                        <div className={`${practicalTaskClasses.FileName}`}>
                          {selectedFile.name.slice(0, 60)}
                        </div>

                        <div
                          onClick={() => {
                            setprofMidea(null);
                            setSelectedFile(null);
                          }}>
                          <img
                            alt="img"
                            src="/practical-task/close.png"
                            style={{width: '20px'}}
                          />
                        </div>
                      </div>
                    )}

                    {profMidea ? (
                      activityData?.media?.profs[0].type == 'document' ? (
                        <img
                          src={profMidea}
                          alt="image"
                          style={{
                            maxWidth: '300px',
                            margin: 'auto',
                            borderRadius: '15px',
                          }}
                        />
                      ) : (
                        <video
                          width="320"
                          height="240"
                          controls
                          className="mt-4"
                          style={{borderRadius: '15px'}}>
                          <source
                            src={activityData?.media?.profs[0].media_path}
                            type="video/mp4"
                          />
                        </video>
                      )
                    ) : (
                      ''
                    )}
                  </div>
                </div>
              </div>
            ) : (
              ''
            )}

            <div className="mb-5">
              <div className="d-flex justify-content-between align-items-center mb-3">
                <h4 className="m-0">Notes</h4>
                <NavLink
                  to="#"
                  style={{
                    color: '#21a6a6',
                    fontWeight: '500',
                    textDecoration: 'underline',
                    display: 'none',
                  }}
                  onClick={() => setShowAllNotes(true)}>
                  See all
                </NavLink>
              </div>

              {activityData?.notes?.length > 0 && (
                <div>
                  {activityData?.notes.map((n: any) => {
                    return (
                      <div className="media mt-3">
                        <div className="d-flex align-items-center mb-3">
                          <img
                            src="/icons/file.png"
                            alt="image"
                            style={{width: '50px', margin: '0 20px 0 0'}}
                          />
                          <div className="w-100">
                            <span>{n.note}</span>
                            <div className="d-flex justify-content-between align-items-center">
                              <p
                                className="m-0 w-100"
                                style={{color: '#b3b3b3'}}>
                                {moment(n.note_added_date)
                                  .locale('en')
                                  .format('D MMM YYYY')}
                              </p>
                              <div className="d-flex align-items-end">
                                <p
                                  className="cursor-pointer"
                                  style={{color: '#0acad7', margin: '0 20px'}}
                                  onClick={() => {
                                    setShow(true);
                                    setSelectedNoteIdValue(n.note);
                                    setSelectedNoteId(n.id);
                                  }}>
                                  Edit
                                </p>
                                <p
                                  className="m-0 secondry-color cursor-pointer"
                                  onClick={() => {
                                    setConfirmDeleteNote(true);
                                    setSelectedNoteId(n.id);
                                  }}>
                                  Delete
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    );
                  })}
                </div>
              )}

              <div>
                <p
                  className="cursor-pointer secondry-color mt-4"
                  style={{fontSize: '18px'}}
                  onClick={() => {
                    setShowAddNote(true);
                  }}>
                  + Add new note
                </p>
              </div>
            </div>

            {autoUpdate == 0 ? (
              <div
                className="mb-5"
                onClick={() => {
                  setUserActivityId(activityData?.user_activity_id);
                  setShowUpdateProgress(true);
                }}>
                <MainButton text={'Update Progress'} />
              </div>
            ) : (
              ''
            )}
          </Col>
        </Row>
      </Container>

      {/*Edit Note*/}
      <Modal show={show} onHide={() => setShow(false)} centered>
        <Modal.Header>
          <Modal.Title></Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <div className="">
            <h5>Edit Note</h5>
            <textarea
              className={`${classes.Textarea} w-100 mb-1 mt-3`}
              rows={5}
              onChange={e => {
                setSelectedNoteIdValue(e.target.value);
              }}>
              {selectedNoteValue}
            </textarea>
            <span className="">Maximum number of characters is 150</span>
          </div>
        </Modal.Body>

        <Modal.Footer>
          <div className="w-100" onClick={() => editNote()}>
            <MainButton text={'Edit note'} />
          </div>
        </Modal.Footer>
      </Modal>

      {/*Add Note*/}
      <Modal show={showAddNote} onHide={() => setShowAddNote(false)} centered>
        <Modal.Header>
          <Modal.Title></Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <div className="">
            <h5>Edit Note</h5>
            <textarea
              className={`${classes.Textarea} w-100 mb-1 mt-3`}
              rows={5}
              onChange={e => {
                setSelectedNoteIdValue(e.target.value);
              }}></textarea>
            <span className="">Maximum number of characters is 150</span>
          </div>
        </Modal.Body>

        <Modal.Footer>
          <div
            className="w-100"
            onClick={() =>
              selectedNoteValue && addNote(activityData.user_activity_id)
            }>
            <MainButton text={'Add note'} />
          </div>
        </Modal.Footer>
      </Modal>

      {/*Confirm delete note*/}
      <Modal
        show={confirmDeleteNote}
        onHide={() => setConfirmDeleteNote(false)}
        centered>
        <Modal.Header>
          <Modal.Title></Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <div className="text-center">
            <h3 className="w-100 mb-4 font-weight-bold">
              Are you sure that you want to delete the selected session ?
            </h3>
          </div>
        </Modal.Body>

        <Modal.Footer>
          <div className="w-100" onClick={() => deleteNote(selectedNoteId)}>
            <MainButton text={'Confirm'} />
          </div>
          <div onClick={() => setConfirmDeleteNote(false)} className="w-100">
            <CancelButton text={'Cancel'} />
          </div>
        </Modal.Footer>
      </Modal>

      {/*Note List*/}
      <Modal show={showAllNotes} onHide={() => setShowAllNotes(false)} centered>
        <Modal.Header>
          <Modal.Title></Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <div className="media p-3">
            <div className="d-flex align-items-center mb-3">
              <img
                src="/icons/file.png"
                alt="image"
                style={{width: '50px', margin: '0 20px 0 0'}}
              />
              <div className="w-100">
                <span>Note 1</span>
                <div className="d-flex justify-content-between align-items-center">
                  <p className="m-0 w-100" style={{color: '#b3b3b3'}}>
                    28 Sep 2023
                  </p>
                  <div className="d-flex align-items-end">
                    <p
                      className="cursor-pointer"
                      style={{color: '#0acad7', margin: '0 20px'}}>
                      Edit
                    </p>
                    <p
                      className="m-0 secondry-color cursor-pointer"
                      onClick={() => setConfirmDeleteNote(true)}>
                      Delete
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="media p-3">
            <div className="d-flex align-items-center mb-3">
              <img
                src="/icons/file.png"
                alt="image"
                style={{width: '50px', margin: '0 20px 0 0'}}
              />
              <div className="w-100">
                <span>Note 1</span>
                <div className="d-flex justify-content-between align-items-center">
                  <p className="m-0 w-100" style={{color: '#b3b3b3'}}>
                    28 Sep 2023
                  </p>
                  <div className="d-flex align-items-end">
                    <p
                      className="cursor-pointer"
                      style={{color: '#0acad7', margin: '0 20px'}}>
                      Edit
                    </p>
                    <p
                      className="m-0 secondry-color cursor-pointer"
                      onClick={() => setConfirmDeleteNote(true)}>
                      Delete
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="media p-3">
            <div className="d-flex align-items-center mb-3">
              <img
                src="/icons/file.png"
                alt="image"
                style={{width: '50px', margin: '0 20px 0 0'}}
              />
              <div className="w-100">
                <span>Note 1</span>
                <div className="d-flex justify-content-between align-items-center">
                  <p className="m-0 w-100" style={{color: '#b3b3b3'}}>
                    28 Sep 2023
                  </p>
                  <div className="d-flex align-items-end">
                    <p
                      className="cursor-pointer"
                      style={{color: '#0acad7', margin: '0 20px'}}>
                      Edit
                    </p>
                    <p
                      className="m-0 secondry-color cursor-pointer"
                      onClick={() => setConfirmDeleteNote(true)}>
                      Delete
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Modal.Body>

        <Modal.Footer></Modal.Footer>
      </Modal>

      {/*Update Progress manually*/}
      <Modal
        show={showUpdateProgress}
        onHide={() => setShowUpdateProgress(false)}
        centered>
        <Modal.Header>
          <Modal.Title></Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <div
            onClick={handleClick}
            style={{
              width: '100%',
              height: '10px',
              backgroundColor: '#ccc',
              position: 'relative',
              borderRadius: '15px',
              cursor: 'pointer',
              marginTop: '25px',
            }}>
            <span
              className={classes.ProgressValue}
              style={{
                color: '#fff',
                background: '#21a6a6',
                borderBottom: '10px solid #21a6a6',
                left: `${styleProgress}%`,
              }}>
              {progress}%
            </span>
            <div
              className="progress"
              style={{
                width: `${progress}%`,
                height: '100%',
                backgroundColor: 'green',
                position: 'absolute',
              }}></div>
          </div>
          <div className="d-flex justify-content-between">
            <div>0%</div>
            <div>100%</div>
          </div>
        </Modal.Body>

        <Modal.Footer>
          <div className="w-100" onClick={() => updateManualProgress()}>
            <MainButton text={'Done'} />
          </div>
        </Modal.Footer>
      </Modal>

      {/*Update Game*/}
      <Modal
        show={showGame}
        onHide={() => setShowGame(false)}
        centered
        size="lg">
        <Modal.Header>
          <Modal.Title></Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <iframe
            title="Your iFrame Title"
            style={{width: '100%', height: '400px'}}
            src={activityData?.media?.game[0].media_path}
            frameBorder="0"
            allowFullScreen></iframe>
        </Modal.Body>

        <Modal.Footer>
          <div
            className="w-100"
            onClick={() => {
              setConfirmUpdate(true);
            }}>
            <MainButton text={'Submit'} />
          </div>
        </Modal.Footer>
      </Modal>

      {/*Confirm update game*/}
      <Modal
        show={confirmUpdate}
        onHide={() => setConfirmUpdate(false)}
        centered>
        <Modal.Header>
          <Modal.Title></Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <div
            className="w-100 mb-3"
            onClick={() => {
              updateAuto(activityData?.media?.game[0].id, 100);
              setConfirmUpdate(false);
              setShowGame(false);
            }}>
            <MainButton text={'Continue With Saving'} />
          </div>
          <div
            onClick={() => {
              updateAuto(activityData?.media?.game[0].id, 0);
              setConfirmUpdate(false);
              setShowGame(false);
            }}
            className="w-100">
            <CancelButton text={'Continue Without Saving'} />
          </div>
        </Modal.Body>

        <Modal.Footer></Modal.Footer>
      </Modal>
    </div>
  );
};

export default UserTrackingActvitiyAddProgress;
