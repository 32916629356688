import Card from 'react-bootstrap/Card';
import '../style/ConsultantSessionCard.css';
import {useNavigate, NavLink} from 'react-router-dom';
import {FaClock, FaDollarSign} from 'react-icons/fa';
import Modal from 'react-bootstrap/Modal';
import {useState} from 'react';
import classes from '../style/ConsultantSessionCard.module.css';
import moment from 'moment';
import Form from 'react-bootstrap/Form';
import {
  JoinRoom,
  SendMail,
  getMeetLink,
} from '../../Consultants/Consultants.service';
import MainButton from '../../../UI/main-button/MainButton';
import {SessionsProp} from '../interface/ISessions';
import {colors} from '../../../constants/colors';
import {cancelSessions, addRating, submitSurvey} from '../Sessions.service';
import {useAppDispatch} from '../../../hooks/hooks';
import {setLoading} from '../../../store/userSlice';
import RateStarsEdit from '../../../UI/rate-stars-edit/RateStarsEdit';

function ConsultantSessionCard({
  session,
  toast,
  upcomming,
  refreshList,
}: SessionsProp) {
  const getStatus = (status: any) => {
    if (status == 1) return 'Pending';
    else if (status == 2) return 'Accepted';
    else if (status == 3) return 'Rejected';
  };

  const userId = localStorage.getItem('id');

  const [show, setShow] = useState(false);
  const [cancelModal, setCancelModal] = useState(false);
  const [showRate, setShowRate] = useState(false);
  const [rate, setRate] = useState(0);
  const [comment, setComment] = useState('');
  const [ratedSessionId, setRatedSessionId] = useState<number>(0);

  const [showSurvey, setShowSurvey] = useState(false);
  const [surveyItems, setSurvetItems] = useState([]);

  const [editedSurveyItems, setEditedSurveyItems] = useState([]);

  const dispatch = useAppDispatch();

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const navigate = useNavigate();

  const comparedays = (date: string) => {
    //return true if more than oe equal today
    let t = new Date();
    let e = new Date(date);
    let startTime = date.slice(
      0,
      date.indexOf('.') > 0 ? date.indexOf('.') : date.length,
    );
    let now = moment(new Date());
    let x = now.diff(startTime, 'minutes');
    let StartSession = x + 10;

    if (StartSession >= 0) return true;
  };
  const handleCopyClick = (link: string) => {
    // Create a temporary input element to copy the value
    const tempInput = document.createElement('input');
    tempInput.value = link;
    document.body.appendChild(tempInput);

    // Select and copy the value
    tempInput.select();
    document.execCommand('copy');
    handleClose();
    toast('copied', 'success');
    // Remove the temporary input
    document.body.removeChild(tempInput);
  };

  const sendLink = async (id: number | string, link: string) => {
    handleClose();
    let email = localStorage.getItem('email');
    let data = new FormData();

    try {
      data.append('consultation_session_id', String(id));
      data.append('email', String(email));
      data.append('link', link);
      let result = await SendMail(data);
      if (result.response) {
        toast('Mail Sended', 'success');
      } else {
        toast('Failed', 'error');
      }
    } catch (e) {
      toast('Failed', 'error');
      console.log({e});
    }
  };

  const cancelSession = async () => {
    setCancelModal(false);
    dispatch(setLoading(true));

    try {
      let data = new FormData();
      data.append('transaction_id', session.transaction_id);
      data.append('model_id', String(session.id));
      data.append('model_name', 'ConsultationSession');
      let result = await cancelSessions(data);
      console.log('resultssss', result);

      if (result.response) {
        if (refreshList) refreshList();
      }
    } catch (e) {
      dispatch(setLoading(false));

      console.log({e});
    }
  };

  const addRate = async () => {
    dispatch(setLoading(true));

    try {
      let data = new FormData();
      data.append('rating', String(rate));
      data.append('comment', String(comment));
      data.append('session_id', String(ratedSessionId));
      let result = await addRating(data);
      console.log('resultssss', result);

      if (result.response) {
        dispatch(setLoading(false));
        setShowRate(false);
        if (refreshList) refreshList();
      }
    } catch (e) {
      dispatch(setLoading(false));
      toast('Done', 'success');

      console.log({e});
    }
  };

  const changeQuestionRate = (e: any, i: any) => {
    console.log(e, i);
    let items: any = editedSurveyItems;
    items[i].rateValue = e;
    console.log('items', items);
    setEditedSurveyItems(items);
  };

  const setSurvey = async () => {
    dispatch(setLoading(true));

    try {
      let data = new FormData();
      editedSurveyItems?.map((i: any, key) => {
        data.append(
          `questionnaires[${key}][questionnaire_id]`,
          i?.questionnaire_id,
        );
        data.append(`questionnaires[${key}][questionnaire_question_id]`, i?.id);
        data.append(`questionnaires[${key}][rate]`, i?.rateValue ?? 0);
      });
      data.append(`type_id`, String(ratedSessionId));
      data.append(`type`, 'ConsultationSession');
      let result = await submitSurvey(data);
      console.log('resultssss', result);

      if (result.response) {
        dispatch(setLoading(false));
        setShowRate(false);
        if (refreshList) refreshList();
      }
    } catch (e) {
      dispatch(setLoading(false));
      toast('Done', 'success');

      console.log({e});
    }
  };

  return (
    <>
      <div
        className={`${classes.Item} d-flex h-100 align-items-center flex-wrap`}>
        <div
          className={`${classes.Owner}`}
          style={{
            padding: '2px 15px',
            right: '0px',
            borderRadius: '0px 10px',
            backgroundColor:
              session?.status == 'completed'
                ? '#39B970'
                : session?.status == 'expired'
                ? '#21a6a6'
                : '#21a6a6',
          }}>
          {session?.status}
        </div>

        <div className="d-flex align-items-center">
          <div className={`${classes.Image}`}>
            <img
              src={
                session?.consultant_avatar
                  ? session?.consultant_avatar
                  : '/icons/manHolder.png'
              }
              alt="course-img"
              style={{
                width: '70px',
                height: '70px',
                marginRight: '10px',
              }}
            />
          </div>
          <div className={`${classes.Details} d-flex flex-column`}>
            <h5 className="font-weight-bold p-0 mb-3">
              {session.consultant_username}
            </h5>

            {`${moment(session?.session_start_date_time).format(
              'dddd  DD-MM-YYYY   hh:mm A',
            )}`}
          </div>
        </div>

        <div
          onClick={() => {
            navigate(`/chat/${userId}/${session?.consultant_id}`, {
              state: {
                current: userId,
                guest: session.consultant_id,
                recAvatar: session?.consultant_avatar,
                senderName: localStorage.getItem('name'),
                recName: session?.consultant_username,
                senderAvatar: localStorage.getItem('avatar'),
                senderFCM: ' await getToken()',
                recFCM: session?.firebase_tokens,
              },
            });
          }}>
          <img
            src={'/icons/chat2.png'}
            alt="course-img"
            style={{
              width: '60px',
              background: '#f1fffe',
              padding: '10px',
              borderRadius: '10px',
              marginLeft: '10px',
            }}
          />
        </div>

        {upcomming && (
          <>
            <div
              className="mt-0"
              style={{ flexBasis: '100%' }}
              onClick={async () => {
                if (comparedays(session.session_start_date_time)) {
                  // handleShow();
                  let name = localStorage.getItem('name') ?? '';
                  let image =
                    localStorage.getItem('avatar') != 'null'
                      ? localStorage.getItem('avatar')
                      : 'http://example.com';

                  let id = localStorage.getItem('id');
                  if( session?.has_private_link != 0 ){

                    window.location.href = session?.consultant_meeting_link
                  }else{


                    let meet = await JoinRoom(
                      name.toString(),
                      'Participante1',
                      session.meeting_id,
                      image!,
                      id!,
                    );
                    if (meet?.data?.token) {
                      console.log('me', meet);

                      navigate(`/meet/${meet.data.token}`);
                    }
                  }
                } else {
                  new Date().getTime() >
                  new Date(session.session_end_date_time).getTime() -
                    5 * 60 * 1000
                    ? toast('Meet Expired', 'error')
                    : toast('Meet Not Start', 'error');
                }
              }}>
              <MainButton text="Start Session Now" />
            </div>
            <div 
            className="mt-1"
            style={{ flexBasis: '100%' }}
            onClick={async () => {}}>
              <MainButton
                onClick={() => {
                  if (comparedays(session.session_start_date_time)) {
                    toast("Can't Cancel Session", 'error');
                  } else {
                    setCancelModal(true);
                  }
                }}
                text="Cancel Session"
                style={{
                  backgroundColor: colors.chamSecColor,
                  borderColor: colors.chamSecColor,
                }}
              />
            </div>
          </>
        )}

        {!upcomming && session?.status == 'completed' && (
          <div
            style={{flexBasis: '100%'}}
            onClick={() => {
              if (session?.is_rated == 0) {
                setShowRate(true);
                setRatedSessionId(session?.id);
              }
            }}>
            <MainButton
              text={`${session?.is_rated == '1' ? 'Rated' : 'Rate Session Now'}`}
              disabled={session?.is_rated == 1}
            />
          </div>
        )}

        {session?.has_user_questionnaire_answers == '0' &&
          session?.status == 'completed' && (
            <div
              className="mt-2"
              style={{flexBasis: '100%'}}
              //onClick={() => {
                //setShowSurvey(true);
                //setRatedSessionId(session?.id);
                //setSurvetItems(session?.questionnaire?.questionnaire_questions);
                //setEditedSurveyItems(
                  //session?.questionnaire?.questionnaire_questions,
                //);
              //}}
              >
              <NavLink to={`/survey/meeting/${session?.id}`}>
                <MainButton text={`Complete Survey`} />
              </NavLink>
            </div>
          )}
      </div>
      {/*
      <Card  className="d-none consultant-card text-center">
        <Card.Img
          variant="top"
          src={
            session?.consultant?.avatar
              ? session?.consultant?.avatar
              : '/icons/manHolder.png'
          }
          style={{
            maxHeight: 200,
          }}
        />
        <Card.Body className="mt-3 mb-4">
          <Card.Title className="consultant-card-title">
            {session.consultant_username}
          </Card.Title>
          <Card.Text className="mb-3 consultant-rate">
            ( {session?.consultant?.consultation_info?.tran_type} )
          </Card.Text>

          <Card.Text>
            <div
              className="mt-4"
              style={{
                justifyContent: 'space-evenly',
                borderBottom: '1px solid #eee',
                paddingBottom: '20px',
              }}>
              <div className="mb-2">
                <FaClock color="#fb5465" style={{fontSize: '1.5rem' , margin :'0 5px'}} />
                {`${moment(session?.session_start_date_time).format(
                  'dddd',
                )} - ${moment(session?.session_start_date_time).format(
                  'DD MMM hh:mm A',
                )}`}
              </div>

              <div>
                <FaDollarSign color="#65c890" style={{fontSize: '1.5rem'}} />{' '}
                Paid
              </div>
            </div>
            {upcomming && (
              <>
                <div
                  className="mt-4"
                  onClick={async () => {
                    if (comparedays(session.session_start_date_time)) {
                      // handleShow();
                      let name = localStorage.getItem('name') ?? '';
                      let image =
                        localStorage.getItem('avatar') != 'null'
                          ? localStorage.getItem('avatar')
                          : 'http://example.com';

                      let id = localStorage.getItem('id');
                      let meet = await JoinRoom(
                        name.toString(),
                        'Participante1',
                        session.meeting_id,
                        image!,
                        id!,
                      );
                      if (meet?.data?.token) {
                        console.log('me', meet);

                        navigate(`/meet/${meet.data.token}`);
                      }
                    } else {
                      new Date().getTime() >
                      new Date(session.session_end_date_time).getTime() -
                        5 * 60 * 1000
                        ? toast('Meet Expired', 'error')
                        : toast('Meet Not Start', 'error');
                    }
                  }}>
                  <MainButton text="Start Session Now" />
                </div>
                <div className="mt-4" onClick={async () => {}}>
                  <MainButton
                    onClick={() => {
                      if (comparedays(session.session_start_date_time)) {
                        toast("Can't Cancel Session", 'error');
                      } else {
                        setCancelModal(true);
                      }
                    }}
                    text="Cancel Session"
                    style={{
                      backgroundColor: colors.green,
                      borderColor: colors.green,
                    }}
                  />
                </div>
              </>
            )}

            {
              !upcomming && session?.status == 'completed' && (
                <div onClick={ () => {
                  if( !session?.is_rated==1 ){
                    setShowRate(true)
                    setRatedSessionId(session?.id)
                  }
                } }>
                 <MainButton
                 text={`${session?.ratings ? 'Rated' : 'Rate Session Now' }`} 
                 disabled={session?.is_rated==1 == true }
                 />
                </div>
              )
            }

            {
              !session?.has_user_questionnaire_answers && session?.status == 'completed' && (
                <div className='mt-2 d-none' onClick={ () => {
                  
                    setShowSurvey(true)
                    setRatedSessionId(session?.id)
                    setSurvetItems(session?.questionnaire?.questionnaire_questions)
                    setEditedSurveyItems(session?.questionnaire?.questionnaire_questions)
                  
                } }>
                 <MainButton
                 text={`Complete Survey`} 
                 
                 />
                </div>
              )
            }


          </Card.Text>
        </Card.Body>
      </Card>
      */}

      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title></Modal.Title>
        </Modal.Header>
        <Modal.Body className="text-center">
          <img src="/logo.png" alt="logo" style={{width: '60px'}} />

          <div
            className="pop-up-des"
            style={{
              padding: '2rem',
              boxShadow: 'rgba(149, 157, 165, 0.2) 0px 8px 24px',
              borderRadius: '10px',
              color: '#fff',
            }}>
            <div className={`desc-title-container mb-3 ${classes.CardTitle}`}>
              <h5
                onClick={async () => {
                  let name = localStorage.getItem('name') ?? '';
                  let image =
                    localStorage.getItem('avatar') != 'null'
                      ? localStorage.getItem('avatar')
                      : 'http://example.com';
                  console.log('image', image);

                  let id = localStorage.getItem('id');

                  let meet = await JoinRoom(
                    name.toString(),
                    'Participante1',
                    session.meeting_id,
                    image!,
                    id!,
                  );
                  if (meet?.data?.token) {
                    console.log('me', meet);

                    navigate(`/meet/${meet.data.token}`);

                    handleClose();
                  }
                }}>
                Start Session
              </h5>
            </div>

            <div className={`desc-title-container mb-3 ${classes.CardTitle}`}>
              <h5
                onClick={async () => {
                  let name = localStorage.getItem('name') ?? '';
                  let image =
                    localStorage.getItem('avatar') != 'null'
                      ? localStorage.getItem('avatar')
                      : 'http://example.com';

                  let id = localStorage.getItem('id');
                  if( session?.has_private_link != 0 ){
                    handleCopyClick(session?.consultant_meeting_link);
                  }else{
                    let meet = await JoinRoom(
                      name.toString(),
                      'Participante1',
                      session?.meeting_id,
                      image!,
                      id!,
                    );
                    console.log('meet2', meet);

                    if (meet?.data?.token) {
                      let link: string = `https://app.dyte.io/v2/meeting?id=${session?.meeting_id}&authToken=${meet.data.token}`;
                      console.log('link', link);

                      let short: any = await getMeetLink(link);
                      console.log('short', short);
                      handleCopyClick(short.response.data);
                    }
                  }
                }}
                className="desc-value">
                Share
              </h5>
            </div>

            <div className={`desc-title-container mb-3 ${classes.CardTitle}`}>
              <h5
                onClick={async () => {
                  let name = localStorage.getItem('name') ?? '';
                  let image =
                    localStorage.getItem('avatar') != 'null'
                      ? localStorage.getItem('avatar')
                      : 'http://example.com';

                  let id = localStorage.getItem('id');
                  let meet = await JoinRoom(
                    name.toString(),
                    'Participante1',
                    session?.meeting_id,
                    image!,
                    id!,
                  );
                  if (meet?.data?.token) {
                    let link: string = `https://app.dyte.io/v2/meeting?id=${session?.meeting_id}&authToken=${meet.data.token}`;
                    console.log('link', link);

                    let short: any = await getMeetLink(link);
                    sendLink(session.id, short.response.data);
                  }
                }}
                className="desc-value">
                Send Link To My Email
              </h5>
            </div>
          </div>
        </Modal.Body>
      </Modal>
      <Modal show={cancelModal} onHide={() => setCancelModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title></Modal.Title>
        </Modal.Header>
        <Modal.Body className="text-center">
          <img src="/logo.png" alt="logo" style={{width: '60px'}} />
          <div
            className="pop-up-des"
            style={{
              padding: '2rem',
              boxShadow: 'rgba(149, 157, 165, 0.2) 0px 8px 24px',
              borderRadius: '10px',
              color: '#fff',
            }}>
            <div onClick={cancelSession} className={`desc-title-container mb-3 ${classes.CardTitle}`}>
              <h5>Cancel Session</h5>
            </div>

            <div className={`desc-title-container mb-3 ${classes.CardTitle}`}>
              <h5
                onClick={async () => setCancelModal(false)}
                className="desc-value">
                Close
              </h5>
            </div>
          </div>
        </Modal.Body>
      </Modal>

      <Modal show={showRate} onHide={() => setShowRate(false)}>
        <Modal.Header closeButton>
          <Modal.Title></Modal.Title>
        </Modal.Header>
        <Modal.Body className="text-center">
          <div className="text-center p-4">
            <img
              src="/review.png"
              className="m-auto"
              style={{width: '100px'}}
            />
            <h5>Review Session</h5>
            <RateStarsEdit
              ratingChanged={(e: any) => {
                setRate(e);
              }}
            />
            <p style={{color: 'gray'}}>Leave FeedBack</p>
            <Form.Control
              className={`form-control mb-4 mt-2 ${classes.TextArea}`}
              as="textarea"
              rows={3}
              onChange={e => setComment(e.target.value)}
              placeholder="Write your commnet"
            />
            <div
              onClick={() => {
                addRate();
              }}>
              <MainButton text={'Send'} disabled={rate == 0} />
            </div>
          </div>
        </Modal.Body>
      </Modal>

      <Modal show={showSurvey} onHide={() => setShowSurvey(false)}>
        <Modal.Header closeButton>
          <Modal.Title></Modal.Title>
        </Modal.Header>
        <Modal.Body className="text-center">
          <div className="text-center pt-2 p-4">
            <div className="mb-3">
              <img src="/logo.png" width={60} />
            </div>

            {surveyItems.map((i: any, index) => {
              return (
                <div>
                  {i.question}
                  <RateStarsEdit
                    ratingChanged={(e: any) => {
                      changeQuestionRate(e, index);
                    }}
                  />
                </div>
              );
            })}

            <div
              onClick={() => {
                setSurvey();
              }}>
              <MainButton text={'Submit Result'} />
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
}

export default ConsultantSessionCard;
