import {Col, Container, Row} from 'react-bootstrap';
import Form from 'react-bootstrap/Form';
import MainButton from '../../UI/main-button/MainButton';
import classes from './style/RequestConsultation.module.css';
import {Modal} from 'react-bootstrap';
import {
  EditProfile,
  getConsultantProfile,
  getFields,
  getJobTitles,
  getLanguages,
} from '../Consultants/Consultants.service';
import {useEffect, useState} from 'react';
import {FaEye, FaCheckCircle} from 'react-icons/fa';
import Dropdown from 'react-bootstrap/Dropdown';
import {useNavigate} from 'react-router-dom';
import toast, {Toaster} from 'react-hot-toast';
import {useAppDispatch} from '../../hooks/hooks';
import {setLoading} from '../../store/userSlice';
import CancelButton from '../../UI/cancel-button/CancelButton';

const ConsultantProfile = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [languages, setLanguges] = useState([]);
  const [languagesSelected, setLanguagesSelected] = useState<any[]>([]);
  const [jobTitles, setJobTitles] = useState([]);
  const [jobTitleSelected, setJobTitleSelected] = useState<any>({});
  const [fields, setFields] = useState([]);
  const [fieldsSelected, setFieldsSelected] = useState<any[]>([]);
  const [specialties, setSpecialties] = useState<any[]>([]);
  const [specialtiesSelected, setSpecialtiesSelected] = useState<any[]>([]);
  const [consType, setConsType] = useState('');
  const [aboutEn, setAbountEn] = useState('');
  const [aboutAr, setAboutAr] = useState('');
  const [ageType, setAgeType] = useState('');
  const [errorsRequest, setErros] = useState<any>({});
  const [disableInputs, setdisableInputs] = useState(true);
  const [showLogoutModal, setShowLogoutModal] = useState<boolean>(false);
  useEffect(() => {
    if( id == '0' && isCons == 'true' ){
      setShowLogoutModal(true)
    }
    getCons(id);
    getLang();
    getJobs();
    getFieldAndSpec('consultant');
  }, []);
  console.log('dfgd', errorsRequest);

  const id =localStorage.getItem('Consid');

  const isCons =localStorage.getItem('isCons');

  const logout = () => {
    localStorage.removeItem('Consid');
    localStorage.removeItem('stars');
    localStorage.removeItem('id');
    localStorage.removeItem('switch');
    localStorage.removeItem('isCons');
    localStorage.removeItem('lang');
    localStorage.removeItem('name');
    localStorage.removeItem('logged');
    localStorage.removeItem('avatar');
    localStorage.removeItem('ratingStars');
    localStorage.removeItem('email');
    localStorage.removeItem('token');
    localStorage.removeItem('feedBack');
    if(localStorage.getItem('i18nextLng') ){
      localStorage.removeItem('i18nextLng');
    }
    
    
    
    window.location.replace('/');
  };

  const getCons = async (id:any) => {
    
    dispatch(setLoading(true));
   
    let result = await getConsultantProfile(id);
   

    console.log('result.response', result.response.data);

    if (result?.response) {
    dispatch(setLoading(false));
      let data = result.response.data
      setAbountEn(data.about_en)
      setAboutAr(data.about_ar)
      setConsType(data.type)
      setJobTitleSelected(data.job_title ?? {})
      setFieldsSelected(data.fields ?? [] )
      setLanguagesSelected(data.languages ?? [] )
      setAgeType(data.type_age)
    }
  
  };

  const edtProfile = async () => {
    let errors = {};
    if (!consType) {
      errors = {...errors, consType: 'Please Select Consultant Type'};
    }
    if (!aboutEn) {
      errors = {...errors, aboutEn: 'Please Enter About En'};
    }
    if (!aboutAr) {
      errors = {...errors, aboutAr: 'Please Enter About Ar'};
    }
    if (!jobTitleSelected?.id) {
      errors = {...errors, jobTitleSelected: 'Please Select Job Title'};
    }
    if (fieldsSelected?.length == 0) {
      errors = {...errors, fieldsSelected: 'Please Select Fields'};
    }

    if (!ageType) {
      errors = {...errors, ageType: 'Please Select Age Type'};
    }
    if (languagesSelected?.length == 0) {
      errors = {...errors, languagesSelected: 'Please Select Languages'};
    }
    setErros(errors);
    if (Object.keys(errors).length === 0) {
      dispatch(setLoading(true));
      let data: FormData = new FormData();
      data.append('_method', 'put');
      data.append('type', consType);
      data.append('about_en', aboutEn);
      data.append('about_ar', aboutAr);
      data.append('job_title_id', String(jobTitleSelected?.id));
      languagesSelected.map((i: any, index) => {
        data.append(`languages[${index}]`, i?.id);
      });
      fieldsSelected.map((i: any, index) => {
        data.append(`fields[${index}]`, i?.id);
      });
      specialtiesSelected.map((i, index) => {
        data.append(`specialty[${index}]`, i.id);
      });
      data.append(`type_age`, ageType);
      // data.append(`email`, email);

      let result = await EditProfile(data,String(id));
      console.log('Data', data);

      console.log('result.response', result.response);

      if (result?.response?.status) {
        dispatch(setLoading(false));
        setdisableInputs(true)
        toast.success('Request Saved Succesfully');
        
       
      }
    }
  };

  const getLang = async () => {
    try {
      let result = await getLanguages();
      if (result.response) {
        console.log('result.response,result.response', result.response.data);
        setLanguges(result.response.data);
      }
    } catch (e) {
      console.log(e);
    }
  };

  const getJobs = async () => {
    try {
      let result = await getJobTitles();
      if (result.response) {
        setJobTitles(result.response.data);
      }
    } catch (e) {
      console.log(e);
    }
  };

  const getFieldAndSpec = async (id: string) => {
    try {
      let result = await getFields(id);

      if (result.response) {
        console.log('result.response.data', result.response.data);

        setFields(result.response.data);
      }
    } catch (e) {
      console.log(e);
    }
  };

  const getSpecOffline = async (fieldsSelected: any) => {
    let x: any[] = [];
    fieldsSelected.map((i: any) => {
      i.specialties.map((item: any) => {
        x.push(item);
      });
    });
    setSpecialties(x);
  };

  // const FieldSelected = (i: number): boolean => {
  //   if (langSelected.find(item => item.id == i)) return true;
  //   else return false;
  // };
  return (
    <div>
      <Container className={classes.FormContainer}>
        <Toaster position="top-right" />
        <Row className="d-flex justify-content-between">
          
          <div className={classes.FormPadding}>
            
            

            <Row>
              <Col xs={{span: 12}} md={{span: 12}}>
                <div className="mt-3 mb-3">
                  <label className="mb-2">Applicant Category</label>
                  <div className={classes.CheckBoxesContainer}>
                    <p className={`${classes.CheckBox} mb-1`}>
                      <input
                        disabled={disableInputs}
                        checked={consType == 'consultant' }
                        type="radio"
                        id="consultant"
                        name="radio-group"
                        onChange={() => {
                          setFieldsSelected([]);
                          setSpecialtiesSelected([]);
                          getFieldAndSpec('consultant');
                          setConsType('consultant');
                        }}
                      />
                      <label htmlFor="consultant">Consultant</label>
                    </p>
                    <p className={`${classes.CheckBox} mb-1`}>
                      <input
                        disabled={disableInputs}
                        checked={consType == 'organization' }
                        type="radio"
                        id="coach"
                        name="radio-group"
                        onChange={() => {
                          setFieldsSelected([]);
                          setSpecialtiesSelected([]);
                          getFieldAndSpec('organization');
                          setConsType('organization');
                        }}
                      />
                      <label htmlFor="coach">Coach</label>
                    </p>
                    <p className={`${classes.CheckBox} mb-1`}>
                      <input
                        disabled={disableInputs}
                        checked={consType == 'teacher' }
                        type="radio"
                        id="teacher"
                        name="radio-group"
                        onChange={() => {
                          setFieldsSelected([]);
                          setSpecialtiesSelected([]);
                          getFieldAndSpec('teacher');
                          setConsType('teacher');
                        }}
                      />
                      <label htmlFor="teacher">Teacher</label>
                    </p>
                  </div>
                  {errorsRequest?.consType && (
                    <div className="text-danger">{errorsRequest?.consType}</div>
                  )}
                </div>
              </Col>

              <Col xs={{span: 12}} md={{span: 12}}>
                <div className="mt-3 mb-3">
                  <label className="mb-2">Job Title</label>
                  <Dropdown autoClose="outside">
                    <Dropdown.Toggle
                      color="red"
                      className={`form-control m-0 ${classes.Drop}`}></Dropdown.Toggle>

                    <Dropdown.Menu>
                      {jobTitles.map((i: any) => {
                        return (
                          <Dropdown.Item
                            disabled={disableInputs}
                            onClick={() => {
                              setJobTitleSelected(i);
                            }}>
                            <Row className="d-flex justify-content-between">
                              <Col md={11}>
                                <div> {i.title}</div>
                              </Col>
                              {jobTitleSelected.id == i.id && (
                                <Col md={1}>
                                  <FaCheckCircle color="#21a6a6" size={20} />
                                </Col>
                              )}
                            </Row>
                          </Dropdown.Item>
                        );
                      })}
                    </Dropdown.Menu>
                  </Dropdown>
                  {jobTitleSelected &&
                    Object.keys(jobTitleSelected).length != 0 && (
                      <div
                        style={{
                          display: 'flex',
                          justifyContent: 'space-between',
                          borderRadius: 10,
                          padding: 8,
                          marginBlock: 10,
                          width: 'fit-content',
                          margin: '10px 5px',
                          background: '#ffe519',
                        }}>
                        <Col>{jobTitleSelected?.title}</Col>
                      </div>
                    )}
                  {errorsRequest?.jobTitleSelected && (
                    <div className="text-danger">
                      {errorsRequest?.jobTitleSelected}
                    </div>
                  )}
                </div>
              </Col>
              <Col xs={{span: 12}} md={{span: 12}}>
                <div className="mt-3 mb-3">
                  <label className="mb-2">Fields</label>
                  <Dropdown autoClose="outside">
                    <Dropdown.Toggle
                      color="red"
                      className={`form-control m-0 ${classes.Drop}`}></Dropdown.Toggle>

                    <Dropdown.Menu>
                      {fields.map((i: any) => {
                        return (
                          <Dropdown.Item
                            disabled={disableInputs}
                            onClick={() => {
                              if (
                                fieldsSelected.find(
                                  (item: any) => item.id == i.id,
                                )
                              ) {
                                getSpecOffline(
                                  fieldsSelected.filter(
                                    (item: any) => i.id != item.id,
                                  ),
                                );
                                setFieldsSelected(
                                  fieldsSelected.filter(
                                    (item: any) => i.id != item.id,
                                  ),
                                );
                              } else {
                                getSpecOffline([...fieldsSelected, i]);
                                setFieldsSelected((prev: any[]) => [
                                  ...prev,
                                  i,
                                ]);
                              }
                            }}>
                            <Row className="d-flex justify-content-between">
                              <Col md={11}>
                                <div> {i.title}</div>
                              </Col>
                              {fieldsSelected.find(item => item.id == i.id) && (
                                <Col md={1}>
                                  <FaCheckCircle color="#21a6a6" size={20} />
                                </Col>
                              )}
                            </Row>
                          </Dropdown.Item>
                        );
                      })}
                    </Dropdown.Menu>
                  </Dropdown>
                  <div className="d-flex" style={{flexWrap: 'wrap'}}>
                    {fieldsSelected.map(i => {
                      return (
                        <div
                          style={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            borderRadius: 10,
                            padding: 8,
                            marginBlock: 10,
                            width: 'fit-content',
                            margin: '10px 5px',
                            background: '#ffe519',
                          }}>
                          <Col>{i.title}</Col>
                          {/* <Col
                            onClick={() => {
                              setLangSelected(
                                langSelected.filter(items => items.id != i.id),
                              );
                            }}
                            style={{
                              alignItems: 'flex-end',
                              textAlign: 'right',
                              margin: '0 0 0 10px',
                            }}>
                            X
                          </Col> */}
                        </div>
                      );
                    })}
                  </div>
                  {errorsRequest?.fieldsSelected && (
                    <div className="text-danger">
                      {errorsRequest?.fieldsSelected}
                    </div>
                  )}
                </div>
              </Col>
              {specialties.length != 0 && (
                <Col xs={{span: 12}} md={{span: 12}}>
                  <div className="mt-3 mb-3">
                    <label className="mb-2">Sepcilist in</label>
                    <Dropdown autoClose="outside">
                      <Dropdown.Toggle
                        color="red"
                        className={`form-control m-0 ${classes.Drop}`}></Dropdown.Toggle>

                      <Dropdown.Menu>
                        {specialties.map(i => {
                          return (
                            <Dropdown.Item
                              disabled={disableInputs}
                              onClick={() => {
                                if (
                                  specialtiesSelected.find(
                                    item => item.id == i.id,
                                  )
                                ) {
                                  setSpecialtiesSelected(
                                    specialtiesSelected.filter(
                                      item => i.id != item.id,
                                    ),
                                  );
                                } else {
                                  setSpecialtiesSelected(prev => [...prev, i]);
                                }
                              }}>
                              <Row className="d-flex justify-content-between">
                                <Col md={11}>
                                  <div> {i.title}</div>
                                </Col>
                                {specialtiesSelected.find(
                                  item => item.id == i.id,
                                ) && (
                                  <Col md={1}>
                                    <FaCheckCircle color="#21a6a6" size={20} />
                                  </Col>
                                )}
                              </Row>
                            </Dropdown.Item>
                          );
                        })}
                      </Dropdown.Menu>
                    </Dropdown>
                    <div className="d-flex" style={{flexWrap: 'wrap'}}>
                      {specialtiesSelected.map(i => {
                        return (
                          <div
                            style={{
                              display: 'flex',
                              justifyContent: 'space-between',
                              borderRadius: 10,
                              padding: 8,
                              marginBlock: 10,
                              width: 'fit-content',
                              margin: '10px 5px',
                              background: '#ffe519',
                            }}>
                            <Col>{i.title}</Col>
                            {/* <Col
                            onClick={() => {
                              setLangSelected(
                                langSelected.filter(items => items.id != i.id),
                              );
                            }}
                            style={{
                              alignItems: 'flex-end',
                              textAlign: 'right',
                              margin: '0 0 0 10px',
                            }}>
                            X
                          </Col> */}
                          </div>
                        );
                      })}
                    </div>
                  </div>
                </Col>
              )}

              <Col xs={{span: 12}} md={{span: 12}}>
                <div className="mt-3 mb-3">
                  <label className="mb-2">Select Learning Language</label>
                  <Dropdown autoClose="outside">
                    <Dropdown.Toggle
                      color="red"
                      className={`form-control m-0 ${classes.Drop}`}></Dropdown.Toggle>

                    <Dropdown.Menu>
                      {languages.map((i: any) => {
                        return (
                          <Dropdown.Item
                            disabled={disableInputs}
                            onClick={() => {
                              if (
                                languagesSelected.find(
                                  (item: any) => item.id == i.id,
                                )
                              ) {
                                setLanguagesSelected(
                                  languagesSelected.filter(
                                    (item: any) => i.id != item.id,
                                  ),
                                );
                              } else {
                                setLanguagesSelected((prev: any[]) => [
                                  ...prev,
                                  i,
                                ]);
                              }
                            }}>
                            <Row className="d-flex justify-content-between">
                              <Col md={11}>
                                <div> {i?.name}</div>
                              </Col>
                              {languagesSelected.find(
                                item => item.id == i.id,
                              ) && (
                                <Col md={1}>
                                  <FaCheckCircle color="#21a6a6" size={20} />
                                </Col>
                              )}
                            </Row>
                          </Dropdown.Item>
                        );
                      })}
                    </Dropdown.Menu>
                  </Dropdown>
                  <div className="d-flex" style={{flexWrap: 'wrap'}}>
                    {languagesSelected.map(i => {
                      return (
                        <div
                          style={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            borderRadius: 10,
                            padding: 8,
                            marginBlock: 10,
                            width: 'fit-content',
                            margin: '10px 5px',
                            background: '#ffe519',
                          }}>
                          <Col>{i.name}</Col>
                          {/* <Col
                            onClick={() => {
                              setLangSelected(
                                langSelected.filter(items => items.id != i.id),
                              );
                            }}
                            style={{
                              alignItems: 'flex-end',
                              textAlign: 'right',
                              margin: '0 0 0 10px',
                            }}>
                            X
                          </Col> */}
                        </div>
                      );
                    })}
                  </div>
                  {errorsRequest?.languagesSelected && (
                    <div className="text-danger">
                      {errorsRequest?.languagesSelected}
                    </div>
                  )}
                </div>
              </Col>

              <Col xs={{span: 12}} md={{span: 6}}>
                <div className="mt-3 mb-3">
                  <label className="mb-2">About En</label>
                  <Form.Control
                    disabled={disableInputs}
                    className={`form-control m-0 ${classes.TextArea}`}
                    as="textarea"
                    rows={3}
                    onChange={e => setAbountEn(e.target.value)}
                    value={aboutEn}
                  />
                  
                </div>
                {errorsRequest?.aboutEn && (
                  <div className="text-danger">{errorsRequest?.aboutEn}</div>
                )}
              </Col>

              <Col xs={{span: 12}} md={{span: 6}}>
                <div className="mt-3 mb-3">
                  <label className="mb-2">About Ar</label>
                  <Form.Control
                    disabled={disableInputs}
                    className={`form-control m-0 ${classes.TextArea}`}
                    as="textarea"
                    rows={3}
                    onChange={e => setAboutAr(e.target.value)}
                    value={aboutAr}
                  />
                </div>
                {errorsRequest?.aboutAr && (
                  <div className="text-danger">{errorsRequest?.aboutAr}</div>
                )}
              </Col>

              <Col xs={{span: 12}} md={{span: 6}}>
                <div className="mt-3 mb-3">
                  <label className="mb-2">Age Stages</label>
                  <div className={classes.CheckBoxesContainer}>
                    <p className={`${classes.CheckBox} mb-1`}>
                      <input
                        disabled={disableInputs}
                        type="radio"
                        id="children"
                        name="radio-group-2"
                        onChange={e => setAgeType('1')}
                        checked={ageType == '1'}
                      />
                      <label htmlFor="children">Children</label>
                    </p>
                    <p className={`${classes.CheckBox} mb-1`}>
                      <input
                        disabled={disableInputs}
                        type="radio"
                        id="teenagers"
                        name="radio-group-2"
                        onChange={e => setAgeType('2')}
                        checked={ageType == '2'}
                      />
                      <label htmlFor="teenagers">Teenagers</label>
                    </p>
                    <p className={`${classes.CheckBox} mb-1`}>
                      <input
                        disabled={disableInputs}
                        type="radio"
                        id="adults"
                        name="radio-group-2"
                        onChange={e => setAgeType('3')}
                        checked={ageType == '3'}
                      />
                      <label htmlFor="adults">Adults</label>
                    </p>
                    <p className={`${classes.CheckBox} mb-1`}>
                      <input
                        disabled={disableInputs}
                        type="radio"
                        id="all-age-stages"
                        name="radio-group-2"
                        onChange={() => setAgeType('4')}
                        checked={ageType == '4'}
                      />
                      <label htmlFor="all-age-stages">All Age Stages</label>
                    </p>
                  </div>
                </div>
                {errorsRequest?.ageType && (
                  <div className="text-danger">{errorsRequest?.ageType}</div>
                )}
              </Col>
            </Row>

           

            <div
                className="mt-4 mb-2"
                onClick={() => {
                  disableInputs ? setdisableInputs(false) : edtProfile()
                }}>
                <MainButton text={ disableInputs ? 'Edit Profile' : 'Save' } />
              </div>

          </div>

          {/* <Col xs={6}  >
                      <img src="/login.png" alt="img" className={classes.LoginBackground} />
                    </Col> */}
        </Row>
      </Container>

      {/*Confirm delete note*/}
      <Modal
        show={showLogoutModal}
        onHide={() => setShowLogoutModal(false)}
        centered>
        <Modal.Header>
          <Modal.Title></Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <div className="text-center">
            <h3 className="w-100 mb-4 font-weight-bold">
              You should logout to show your data
            </h3>
          </div>
        </Modal.Body>

        <Modal.Footer>
          <div className="w-100" onClick={() => logout()}>
            <MainButton text={'Logout'} />
          </div>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default ConsultantProfile;
