import moment from 'moment';
import {removeCart} from '../Cart.services';
import {CartDataProp} from '../interface/ICart';
import '../style/ShoppingCartItem.css';
import { getUserCurrency } from '../../../helpers/getUserCurrency';
import { useTranslation } from 'react-i18next';
const ShoppingCartItem = ({data}: CartDataProp) => {
  const {t} = useTranslation();
  const userCountry = getUserCurrency()
  
  const getType = (type:any, startDate:any, date:any , tracking_type:any ) => {
    if( type == 'Tracking' ){
      if( startDate ){
        return 'Challenge'
      }else{
        return 'Tracking'
      }
    }else if ( type == 'TrackingPackage' ){
      if( tracking_type == 'tracking' ){
        return 'Follow-up System Package'
      }else{
        return 'Challenge Package'
      }
    }if( type == 'Course' ){
      if( date ){
        return 'Live Course'
      }else{
        return type
      }
    }
    else{
      return type
    }
  };
  return (
    <div className="course-item d-flex row">
      <span
        className={`course-type ${
          data.model_name === 'live'
            ? 'recorded-course-type'
            : 'live-course-type'
        }`}>
        

      {getType(data?.model_name , data?.start_date , data?.date , data?.tracking_type )}
        
      </span>
      {/* <div className="course-image col-md-3">
        <img src={data.image} alt="course-img" />
      </div> */}
      <div className="course-details col-md-9">
        <div className="d-flex flex-column justify-content-between h-100">
          

          <div className="secondry-color  mb-2" style={{ fontSize : '20px' , fontWeight : '500' }}>
          {data.model_name == 'ConsultationSession' ? 
          moment(data.date).format('dddd DD-MM-YYYY hh:mm A')
           : '' }
          </div>
          <div className="d-flex justify-content-between align-items-center">
            <div>
              <div className="discount-price" style={{fontSize: '2rem'}}>
                {data.price} {userCountry}
              </div>
            </div>

            <span
              style={{color: '#7f7f7f', fontWeight: 'bold'}}
              onClick={() => {
                removeCart(data.id,data.is_soldout,data.is_not_available);
              }}>
              <img
                src="/icons/deleteIconActive.png"
                alt="img"
                width={25}
                style={{verticalAlign: 'text-bottom', color: '#7f7f7f'}}
              />{' '}
              Delete
            </span>
          </div>
        </div>
      </div>

      

      {data.is_soldout
      ?        
      <div
      style={{
        background: '#c7c7c7',
        textAlign: 'center',
        fontSize: '22px',
        padding: '1rem',
        borderRadius: '0px 0 10px 10px'

      }}  
      >
        <strong>{data.is_soldout ? 'Sold Out' : '' }</strong>
      </div>
      :
      ''
      }

      {data.is_not_available
      ?        
      <div
      style={{
        background: '#c7c7c7',
        textAlign: 'center',
        fontSize: '22px',
        padding: '1rem',
        borderRadius: '0px 0 10px 10px'

      }}  
      >
        <strong>{data.is_not_available ? 'Un Covered Country' : '' }</strong>
      </div>
      :
      ''
      }
    </div>
  );
};

export default ShoppingCartItem;
