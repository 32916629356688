import {useEffect, useState} from 'react';
import {Col, Container, Row} from 'react-bootstrap';
import {NavLink, useNavigate,useLocation} from 'react-router-dom';
import MainButton from '../../UI/main-button/MainButton';
import classes from './style/login.module.css';
import {FaEye} from 'react-icons/fa';
import {LoginAccount, SocialLogin} from './Auth.service';
import {useGoogleLogin} from '@react-oauth/google';
import toast, {Toaster} from 'react-hot-toast';


const Login = () => {
  const location = useLocation();
  const [redirectTo, setRedirectTo] = useState<string>('');
  const distenationRoute = localStorage.getItem('distenationRoute') || '/'

  const [createAccountLink, setCreateAccountLink] = useState<string>('/sign-up');

  useEffect(() => {

    if( location.state && location.state.location ){
      setRedirectTo(`${location.state.location}`)
    }else{
      setRedirectTo('/')
    }

    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    
    const type = urlParams.get('type')
    const modelId = urlParams.get('model_id')
    const invitationId = urlParams.get('invitation_id')
    
    if(  type && modelId ){
      if( type == 'Initiative' ){ 
        setCreateAccountLink(`/sign-up?type=Initiative&model_id=${modelId}&invitation_id=${invitationId}`)
      }else if( type == 'InitiativePackage' ){
        setCreateAccountLink(`/sign-up?type=InitiativePackage&model_id=${modelId}&invitation_id=${invitationId}`)
      }else if( type == 'Event' ){
        navigate(`/event/${modelId}/details`)
      }else if( type == 'tracking' ){ 
        setCreateAccountLink(`/sign-up?type=tracking&model_id=${modelId}`)
      }else if( type == 'trackingPackage' ){ 
        setCreateAccountLink(`/sign-up?type=trackingPackage&model_id=${modelId}`)
      }else if( type == 'Course' ){ 
        setCreateAccountLink(`/sign-up?type=Course&model_id=${modelId}`)
      }else if( type == 'Consultant' ){ 
        setCreateAccountLink(`/sign-up?type=Consultant&model_id=${modelId}`)
      }
    }

    if( type == 'InitiativesList' ){
      navigate('/user/initiatives/')
    }else if( type == 'FollowUpSystemsList' ){
      navigate('/user/trackings/list/')
    }else if( type == 'FollowUpSystemsList' ){
      navigate('/consultants/get-teachers')
    }

  }, []);

  


  const navigate = useNavigate();
  const [email, setEmail] = useState<string>('');
  
  const [password, setPassword] = useState<string>('');
  const [showPassword, setShowPassword] = useState<boolean>(false);
  const [error, setError] = useState<string>('');
  const queryParams = new URLSearchParams(location.search);

  
 

  const Login = async () => {
    if (email == '' || password == '') {
      setError('Wrong Data');
    } else {
      let data = new FormData();
      data.append('email', email);
      data.append('password', password);
      let result = await LoginAccount(data);
      console.log('RET', result);
      console.log('logn res', result?.response);
      if (result?.response?.access_token) {
        localStorage.setItem('logged', '1');
        localStorage.setItem('token', result.response.access_token);
        localStorage.setItem('name', result.response.user.username);
        localStorage.setItem('email', result.response.user.email);
        localStorage.setItem('avatar', result.response.user.avatar);
        localStorage.setItem('id', result.response.user.id);
        localStorage.setItem('isCons', result.response.user.is_consultant);
        localStorage.setItem('switch', '0');
        localStorage.setItem('hasPassword', result.response.user.hasPassword);
        localStorage.setItem('is_social', '0');
        

        localStorage.setItem(
          'Consid',
          result.response.user.consultation_info
            ? result.response.user.consultation_info.id
            : 0,
        );
        localStorage.setItem(
          'feedBack',
          result.response.user.consultation_info
            ? JSON.stringify(result.response.user.consultation_info?.feedBack)
            : JSON.stringify([]),
        );
        localStorage.setItem(
          'ratingStars',
          result.response.user.consultation_info
            ? JSON.stringify(
                result.response.user.consultation_info?.rating_stars,
              )
            : JSON.stringify([]),
        );
        localStorage.setItem(
          'stars',
          result.response.user.consultation_info
            ? result.response.user.consultation_info?.ratings
            : 0,
        );

        
        if(  queryParams.get('type') && queryParams.get('model_id') ){
          let type = queryParams.get('type')
          let modelId = queryParams.get('model_id')
          if( type == 'Initiative' ){
            let invitationId = queryParams.get('invitation_id')
            window.location.href = `/user/trackings/${modelId}/details?is_level=0&type=Initiative&model_id=${modelId}&invitation_id=${invitationId}`
            
          }else if( type == 'InitiativePackage' ){
            let invitationId = queryParams.get('invitation_id')
           
            window.location.href = `/user/initiatives/${modelId}/package/levels?discover=1&type=InitiativePackage&model_id=${modelId}&invitation_id=${invitationId}`
          }else if( type == 'Event' ){
           
            window.location.href = `/event/${modelId}/details?type=Event&model_id=${modelId}`
          }else if( type == 'tracking' ){
           
            window.location.href = `/user/trackings/${modelId}/details?is_level=0`
          }else if( type == 'trackingPackage' ){
           
            window.location.href = `/user/trackings/${modelId}/package/levels?discover=1`
          }else if( type == 'Course' ){
           
            window.location.href = `/courses/${modelId}`
          }else if( type == 'Consultant' ){
           
            window.location.href = `/courses/${modelId}`
          }
        }else{
          window.location.href = distenationRoute;
        }
        
        
        
        
      } else {
        if (result?.error?.response?.status == 400) {
          setError(result?.error?.response?.data?.msg);
        } else {
          setError('invalid Data');
        }
      }
    }
  };

  const login = useGoogleLogin({
    onSuccess: tokenResponse => {
      console.log('tokenResponse', tokenResponse.access_token);

      // if (tokenResponse && tokenResponse?.accessToken) {
      console.log('tokenResponse2', tokenResponse.access_token);
      // Use the access token to fetch user details
      fetch('https://www.googleapis.com/oauth2/v2/userinfo', {
        headers: {
          Authorization: `Bearer ${tokenResponse.access_token}`,
        },
      })
        .then(response => response.json())
        .then(async user => {
          console.log(user);
          localStorage.setItem('email', user.email);
          localStorage.setItem('avatar', user.picture);
          let data = new FormData();
          data.append('provider', 'google');
          data.append('provider_id', user.id);
          data.append('email', user.email);
          // data.append('firebase_token', await gettoken());
          let result = await SocialLogin(data);
          console.log('resultresultresult', result.response);
          if (!result.response.success) {
            toast.error(result?.response?.message);
          } else if (result.response?.token) {
            console.log('social data ' , result.response);
            localStorage.setItem('logged', '1');
            localStorage.setItem('token', result.response.token);
            localStorage.setItem('name', result.response.user?.username);
            localStorage.setItem('isCons', result.response.user.is_consultant);
            localStorage.setItem('hasPassword', result.response.user.hasPassword);
            localStorage.setItem('is_social', '1');

            localStorage.setItem(
              'id',
              JSON.stringify(result.response.user?.id),
            );
            localStorage.setItem(
              'gender',
              result.response.user?.gender == 1 ? '1' : '2',
            );
            result.response.user?.avatar &&
              localStorage.setItem('avatar', result.response.user?.avatar);

              if(  queryParams.get('type') && queryParams.get('model_id') ){
                let type = queryParams.get('type')
                let modelId = queryParams.get('model_id')
                if( type == 'Initiative' ){
                  let invitationId = queryParams.get('invitation_id')
                  window.location.href = `/user/trackings/${modelId}/details?is_level=0&type=Initiative&model_id=${modelId}&invitation_id=${invitationId}`
                  
                }else if( type == 'InitiativePackage' ){
                  let invitationId = queryParams.get('invitation_id')
                 
                  window.location.href = `/user/initiatives/${modelId}/package/levels?discover=1&type=InitiativePackage&model_id=${modelId}&invitation_id=${invitationId}`
                }else if( type == 'Event' ){
                  window.location.href = `/event/${modelId}/details?type=Event&model_id=${modelId}`
                }else if( type == 'tracking' ){
                  window.location.href = `/user/trackings/${modelId}/details?is_level=0` 
                }else if( type == 'trackingPackage' ){
           
                  window.location.href = `/user/trackings/${modelId}/package/levels?discover=1`
                }else if( type == 'Course' ){
           
                  window.location.href = `/courses/${modelId}`
                }else if( type == 'Consultant' ){
           
                  window.location.href = `/consultants/consultant/${modelId}`
                }
              }else{
                window.location.href = '/';
              }
          } else {
            const queryString = window.location.search;
            const urlParams = new URLSearchParams(queryString);
            const type = urlParams.get('type')
            const modelId = urlParams.get('model_id')
            const invitationId = urlParams.get('invitation_id')
          
            let inviteParems = ``
            if(  type && modelId ){
              if( type == 'Initiative' ){
                inviteParems += `?type=Initiative&model_id=${modelId}&invitation_id=${invitationId}`
              }else if( type == 'InitiativePackage' ){
                inviteParems = `?type=InitiativePackage&model_id=${modelId}&invitation_id=${invitationId}`
              }else if( type == 'Event' ){
                inviteParems = `?type=Event&model_id=${modelId}`
              }else if( type == 'tracking' ){
                inviteParems = `?type=tracking&model_id=${modelId}`
              }else if( type == 'trackingPackage' ){
                inviteParems = `/?type=trackingPackage&model_id=${modelId}`
              }else if( type == 'Course' ){
                inviteParems = `/?type=Course&model_id=${modelId}`
              }else if( type == 'Consultant' ){
                inviteParems = `/?type=Consultant&model_id=${modelId}`
              }
            }

            localStorage.setItem('hasPassword', 'false');
            localStorage.setItem('is_social', '1');

            navigate(`/register${inviteParems}`, {
              state: {
                social: true,
                provider_id: user.id,
                provider: 'google',
                email: user.email,
              },
            });
          }
        })
        .catch(error => {
          console.error('Error fetching user details:', error);
        });
    },
  });

  return (
    <div style={{ background: "url('/login-bg.png')" , backgroundSize: 'cover' }}>
      <Container className={classes.FormContainer}>
        <Toaster position="top-right" />
        <Row className="d-flex justify-content-center">
          <Col md={{span: 4}} className='my-5'>
            <div className={classes.FormPadding}>
              <div className='text-center'>
                <img src="/simple-logo.png" alt="img" />
                <h2 className={classes.SignTitle}>Sign In</h2>
              </div>

              <div className="col-12 text-center">
                
                  <small className={`${classes.GrayTitle} mr-2`}>
                    You Don't have an account? &nbsp;
                  </small>

                  <NavLink
                    to={createAccountLink}
                    className={`text-primary font-weight-bold ${classes.CreateOne} `}>
                    {' '}
                    Create New Account
                  </NavLink>
                
              </div>

              <div onClick={() => login()} className={classes.GoogleSignIn}>
                <img src="/icons/google.png" alt="img" /> Sign in with Google
              </div>

              <p className={`fontWeight-bold ${classes.HeaderTitle} `}>
                <span>Or Login By Email</span>
              </p>

              <div className="mt-3 mb-3">
                <input
                  type="email"
                  className={`form-control m-0 ${classes.EmailInput}`}
                  placeholder="Email"
                  required
                  onChange={e => setEmail(e.target.value)}
                />
              </div>

              <div className="mt-3 mb-3" style={{position: 'relative'}}>
                <div
                  className={classes.togglePassword}
                  onClick={() => setShowPassword(!showPassword)}>
                  {!showPassword ? (
                    <img src="/eye.png" alt="img" width={25} />
                  ) : (
                    <FaEye color="#08cad7" size={20} />
                  )}
                </div>
                <input
                  type={showPassword ? 'text' : 'password'}
                  className={`form-control m-0 ${classes.PasswordInput}`}
                  placeholder="Password"
                  required
                  onChange={e => setPassword(e.target.value)}
                />
                <div className={classes.error}>{error}</div>
                
              </div>
              <div className="mt-4 mb-2 Sign-in">
                <MainButton text="Sign In" onClick={Login} />
              </div>

              <p className={classes.forgetPassword}>
                <NavLink
                  to="/forget-password"
                  style={({isActive}) => ({
                    color: '#161313',
                    fontWeight: '400',
                  })}
                  className="mr-auto">
                  Forget Password?
                </NavLink>
              </p>

              
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default Login;
