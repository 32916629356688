import {Button, Container} from 'react-bootstrap';
import classes from './style/PracticalTaskUplaod.module.css';
import {useState} from 'react';
import {useLocation, useNavigate} from 'react-router';
import {submitPracticalTask} from './Courses.service';
import LoadingSpinner from '../../components/LoadingSpinner/LoadingSpinner';
import toast, {Toaster} from 'react-hot-toast';
const PracticalTaskUplaod = () => {
  const location = useLocation();
  const navigate = useNavigate();

  const taskId: number = location.state.item.practical_task.id;
  const [loading, setLoading] = useState<boolean>(false);
  const [selectedFile, setSelectedFile] = useState<File | null>(null);

  const submit = async () => {
    setLoading(true);
    let data = new FormData();
    data.append('practical_task_id', JSON.stringify(taskId));
    data.append('attachment', selectedFile!);

    let result = await submitPracticalTask(data);

    if (result.response) {
      console.log('resultresultresult,result', result);
      toast.success('Done');
      setTimeout(() => {
        navigate(-1);
      }, 1000);
    }
    setLoading(false);
  };
  const handleFileChange = (event: any) => {
    const file = event.target.files[0];
    setSelectedFile(file);
  };

  //   const handleOpenFile = () => {
  //     if (selectedFile) {
  //       const blobUrl = URL.createObjectURL(selectedFile);
  //       console.log('blobUrlblobUrl', blobUrl);

  //       window.open(blobUrl, '_blank');
  //     }
  //   };
  return (
    <Container>
      {loading && (
        <div className={classes.LoaderDiv}>
          <LoadingSpinner />
        </div>
      )}
      <Toaster position="top-right" />
      <section>
        <div>
          <h1 className="main-black-title mb-5">Practical Task</h1>

          <h5 className="d-flex mb-3">
            <img
              alt="img"
              src="/practical-task/success.png"
              style={{width: '25px', marginRight: '7px'}}
            />
            <span>Upload High Resolution Image</span>
          </h5>

          <h5 className="d-flex mb-3">
            <img
              alt="img"
              src="/practical-task/success.png"
              style={{width: '25px', marginRight: '7px'}}
            />
            <span>Or Uplaod PDF</span>
          </h5>

          <h5 className="d-flex mb-3">
            <img
              alt="img"
              src="/practical-task/success.png"
              style={{width: '25px', marginRight: '7px'}}
            />
            <span>Or Word, Doc Files</span>
          </h5>
          {!selectedFile && (
            <div className={`${classes.UploadBtnWrapper} w-100 mb-4 mt-4`}>
              <button className="w-100 p-4">
                <img
                  alt="img"
                  src="/practical-task/clip.png"
                  style={{width: '55px'}}
                />
                <div
                  style={{
                    color: '#21a6a6',
                    marginTop: '1rem',
                    fontWeight: 'normal',
                  }}>
                  <strong style={{textDecoration: 'underline'}}>Click</strong>{' '}
                  to attach your test result
                </div>
              </button>
              <input
                type="file"
                name="myfile"
                className={classes.UploadBtnWrapper}
                onChange={handleFileChange}
                id="pictureX"
              />
            </div>
          )}
          {selectedFile && (
            <div
              className={`${classes.FilePreview} d-flex justify-content-between align-items-center`}>
              <div>
                <img
                  alt="img"
                  src="/practical-task/clip.png"
                  style={{width: '26px'}}
                />
              </div>

              <div className={`${classes.FileName}`}>
                {selectedFile.name.slice(0, 60)}
              </div>

              <div onClick={() => setSelectedFile(null)}>
                <img
                  alt="img"
                  src="/practical-task/close.png"
                  style={{width: '20px'}}
                />
              </div>
            </div>
          )}
          <Button
            onClick={submit}
            className={classes.SubmitButton}
            variant="primary">
            Submit Result
          </Button>
        </div>
      </section>
    </Container>
  );
};

export default PracticalTaskUplaod;
