import moment from 'moment';
import {colors} from '../../../constants/colors';
import {SlotProp} from '../interface/IAvailabilty';
import {truncate} from '../../../helpers/truncate';

const AvailabiltyItem = ({Slot, deleteSlot}: SlotProp) => {
  return (
    <>
      <div
        style={{
          border: '2px solid #21a6a6',
          padding: '10px 15px',
          borderRadius: '15px',
          position: 'relative',
          marginBottom: '20px',
          backgroundColor: Slot?.reserved == 1 ? '#E4E3E3' : colors.white,
        }}>
        <div className="d-flex justify-content-between align-items-center">
          <div
            style={{
              border: '1px solid #9d9d9d',
              padding: '5px 10px',
              borderRadius: '10px',
              textAlign: 'center',
            }}>
            <div>{Slot.duration}</div>
            <div>Min</div>
          </div>

          <div
            style={{
              fontWeight: '500',
              padding: '0 10px',
              textAlign: 'center',
            }}>
            {moment(Slot.from, 'HH:mm:ss').format('h:mm A')}
          </div>

          <div>
            <img
              src="/icons/deleteIconActive.png"
              style={{
                cursor: 'pointer',
                width: '25px',
              }}
              onClick={() => deleteSlot(Slot)}
            />
          </div>
        </div>

        <hr></hr>

        <div>
          {
            Slot?.reserved == 0 ?
            <img
            src="/bank-question.png"
            style={{
              backgroundColor: 'transparent',
              width: 28,
              height: 28,
              borderRadius: 48,
            }}
          />
            
            :

            <img
            src={Slot?.avatar ?? '/icons/manHolder.png' }
            style={{
              backgroundColor: 'transparent',
              width: 28,
              height: 28,
              borderRadius: 48,
            }}
          />
          }
          
          <span
            style={{
              fontWeight: '500',
              color: '#21a6a6',
              padding: '0 7px',
            }}>
            {Slot?.reserved == 0
              ? 'not Booked'
              : Slot?.username
              ? truncate(Slot?.username, 12)
              : ''}
          </span>
        </div>
      </div>
    </>
  );
};

export default AvailabiltyItem;
